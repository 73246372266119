import { useState, useEffect } from "react"

// Check if window is defined (so if in the browser or in node.js).
const isBrowser = typeof window !== "undefined"

// Sort the people by surname, but keep special persons at the top
const sortPeople = items => {
  const specialPersons = ["Juhana Huotarinen", "Kustaa Huhtala"]

  const getSurname = name => {
    return name.split(" ").slice(-1)[0].toLowerCase()
  }

  const sortedPeople = items.slice().sort((a, b) => {
    const aIsSpecial = specialPersons.includes(a.name)
    const bIsSpecial = specialPersons.includes(b.name)

    if (aIsSpecial && bIsSpecial) {
      return specialPersons.indexOf(a.name) - specialPersons.indexOf(b.name)
    } else if (aIsSpecial) {
      return -1
    } else if (bIsSpecial) {
      return 1
    }

    return getSurname(a.name).localeCompare(getSurname(b.name))
  })
  return sortedPeople
}

export const useItems = (allItemsInitial, gridRef) => {
  const [allItems, setAllItems] = useState([])
  const [resize, setResize] = useState()
  const [pageSize, setPageSize] = useState(12)
  const [visibleItems, setVisibleItems] = useState([])
  const [hasMore, setHasMore] = useState(allItems.length > visibleItems.length)

  useEffect(() => {
    if (visibleItems.length < allItems.length) {
      setHasMore(true)
    } else {
      setHasMore(false)
    }
  }, [visibleItems.length, allItems.length])

  //set pageSize based on grid column count
  useEffect(() => {
    const updatePageSize = size => {
      setPageSize(size)
      setVisibleItems(allItems.slice(0, size))
    }

    if (isBrowser && gridRef?.current) {
      const gridComputedStyle = window.getComputedStyle(gridRef.current)

      // get number of grid columns
      const gridColumnCount = gridComputedStyle
        .getPropertyValue("grid-template-columns")
        .split(" ").length

      if (gridColumnCount === 3) {
        updatePageSize(12)
      }
      if (gridColumnCount === 2) {
        updatePageSize(8)
      }
      if (gridColumnCount === 1) {
        updatePageSize(6)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resize])

  //update incase user resizes window
  useEffect(() => {
    if (isBrowser) {
      const handleResize = () => {
        setResize(window.innerWidth)
      }
      window.addEventListener("resize", handleResize)
    }
  })

  const loadMore = () => {
    setVisibleItems(allItems.slice(0, visibleItems.length + pageSize))
  }

  const updateVisibleItems = items => {
    const sortedItems = sortPeople(items)
    setAllItems(sortedItems)
    setVisibleItems(sortedItems.slice(0, pageSize))
  }

  return [visibleItems, hasMore, loadMore, updateVisibleItems]
}
