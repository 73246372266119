import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

const ExploreLink = styled(Link)`
  position: relative;
  font-size: 0.8125em;
  font-family: var(--ff-secondary);
  font-weight: 700;
  letter-spacing: 0.0046em;

  text-transform: uppercase;
  text-decoration: none;
  text-align: center;
  color: var(--clr-text-blue);

  height: fit-content;
  margin: 0.47rem 0;
  margin-top: auto;
  margin-bottom: auto;

  cursor: pointer;

  @media (min-width: 40em) {
    font-size: 1em;
    line-height: 25px;
  }

  &:hover {
    text-decoration: underline;
  }
`

const TextMobile = styled.span`
  @media (min-width: 40em) {
    display: none;
  }
`

const TextDesktop = styled.span`
  @media (max-width: 40em) {
    display: none;
  }
`

const ExploreButton = () => {
  return (
    <ExploreLink to="/open-positions/cloud-architect" title="Explore jobs">
      <TextDesktop>Explore open positions</TextDesktop>
      <TextMobile>Explore jobs</TextMobile>
    </ExploreLink>
  )
}

export default ExploreButton
