import React from "react"
import styled from "styled-components"
import EmailIcon from "../../assets/svgs/mail.svg"
import LinkedInIcon from "../../assets/svgs/linked.svg"
import GithubIcon from "../../assets/svgs/github.svg"
import LinkIcon from "../../assets/svgs/link.svg"
import MediumIcon from "../../assets/svgs/medium.svg"
import PhoneIcon from "../../assets/svgs/phone.svg"
import StackoverflowIcon from "../../assets/svgs/stackoverflow.svg"
import { StaticImage } from "gatsby-plugin-image"

const StyledLink = styled.a`
  .gatsby-image-wrapper {
    height: 28px;
    width: 28px;
  }
  display: inline-block;
`

const PersonalLinksContainer = styled.div`
  display: inline-flex;
  padding-left: 0;
  a {
    align-items: left;
    margin-right: 0.5rem;
  }
  svg {
    align-self: left;
    width: 1.75rem;
    height: 1.75rem;
  }
`

const PersonalLinks = ({ personalLinks, togglePhone, toggleEmail }) => {
  const { email, github, phone, wa, linkedIn, medium, stackoverflow, other } =
    personalLinks
  return (
    <PersonalLinksContainer>
      {phone && (
        <>
          <StyledLink
            aria-label="Chat on WhatsApp"
            href={`https://wa.me/${wa}`}
            target="_blank"
            rel="noreferrer"
          >
            <StaticImage src="../../images/common/whatsapp_logo_green.png" />
          </StyledLink>
          <a onClick={() => togglePhone()} href={`tel:${phone}`} title={phone}>
            <PhoneIcon />
          </a>
        </>
      )}
      {email && (
        <a onClick={() => toggleEmail()} href={`mailto:${email}`} title={email}>
          <EmailIcon />
        </a>
      )}
      {linkedIn && (
        <a target="_blank" rel="noreferrer" href={linkedIn} title={linkedIn}>
          <LinkedInIcon />
        </a>
      )}
      {github && (
        <a target="_blank" rel="noreferrer" href={github} title={github}>
          <GithubIcon />
        </a>
      )}
      {medium && (
        <a target="_blank" rel="noreferrer" href={medium} title={medium}>
          <MediumIcon />
        </a>
      )}

      {stackoverflow && (
        <a
          target="_blank"
          rel="noreferrer"
          href={stackoverflow}
          title={stackoverflow}
        >
          <StackoverflowIcon />
        </a>
      )}

      {other && (
        <a target="_blank" rel="noreferrer" href={other} title={other}>
          <LinkIcon />
        </a>
      )}
    </PersonalLinksContainer>
  )
}

export default PersonalLinks
