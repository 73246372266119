import * as React from "react"
import { graphql } from "gatsby"
import MeetOurPeople from "../components/our-people/MeetOurPeople"
import Meta from "../components/common/Meta"
import Layout from "../components/common/Layout"
import Main from "../components/common/Main"
import PageIntro from "../components/common/intro/PageIntro"
import styled from "styled-components"
import { StaticImage } from "gatsby-plugin-image"
import ApplyBox from "../components/common/formboxes/ApplyBox"

const ImageContainer = styled.div`
  width: min(1000px, 100% - 2.5rem);
  margin-inline: auto;
  position: relative;
  margin-top: 2rem;
  @media (min-width: 40em) {
    margin-top: 5.5rem;
  }
`

export const Head = () => (
  <Meta
    title="Rebase Team - Get to Know Our Skilled Professionals"
    description="Meet Rebase's experts. With no hierarchy and shared ownership, we build the future together, specialising in software, cloud, data, QA, and agility."
  />
)

const OurPeoplePage = ({ data }) => {
  const title = [["Thinkers,", "Problem Solvers &", "Enablers"]]

  return (
    <Layout>
      <Main>
        <PageIntro
          title={title}
          subheading="Even if we Rebasers work in various roles, we all have 
          something in common: experience, independence, and a leader’s mindset. 
          We are all equal, building our future together, and most of our employees 
          are also company shareholders. No hierarchy, no departments, and no decisions 
          made behind closed doors."
        />
        <ImageContainer>
          <StaticImage
            src="../images/our-people/ourpeople_011.jpg"
            alt="Aki Nevalainen smiling"
          />
        </ImageContainer>
        <MeetOurPeople data={data} />
      </Main>
      <ApplyBox />
    </Layout>
  )
}

export const query = graphql`
  query allPeople {
    allPeopleDataJson {
      nodes {
        id
        name
        title
        image {
          childImageSharp {
            gatsbyImageData(width: 320, quality: 100, placeholder: BLURRED)
          }
        }
        personalLinks {
          email
          github
          linkedIn
          medium
          other
          phone
          wa
          stackoverflow
        }
      }
    }
  }
`

export default OurPeoplePage
