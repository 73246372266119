import * as React from "react"
import styled from "styled-components"
import ApplyForm from "../form/ApplyForm"
import BackgroundImage from "./BackgroundImage"
import ContentWrapper from "./ContentWrapper"
import FormBoxContainer from "./FormBoxContainer"
import { PrimaryTitle } from "./Titles"
import PrivacyLabel from "../form/PrivacyLabel"
import ContactInfoBox from "./ContactInfoBox"

const ApplyBoxTitles = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (min-width: 40em) {
    align-items: start;
  }
  @media (min-width: 64em) {
    align-items: center;
  }
`

const FormPrimaryTitle = styled(PrimaryTitle)`
  margin-bottom: 0;
`

const LocationLabel = styled(PrivacyLabel)`
  margin-inline: auto;
  margin-bottom: 2rem;

  font-size: 0.75em;
  font-weight: 600;
  font-family: var(--ff-primary);

  line-height: 18px;
  letter-spacing: 0.0015em;

  text-align: center;

  @media (max-width: 64em) {
    font-family: var(--ff-secondary);
    font-weight: 600;
    font-size: 0.875em;
    line-height: 23px;
    letter-spacing: 0.05em;
  }
`

const ApplyBox = () => {
  return (
    <>
      <BackgroundImage />
      <FormBoxContainer>
        <ContentWrapper id="apply">
          <ApplyBoxTitles>
            <FormPrimaryTitle>Want to be a part of Rebase?</FormPrimaryTitle>
            <LocationLabel>
              Please note that we currently only consider applicants based in
              Finland
            </LocationLabel>
            <ContactInfoBox
              phone="+358504065219"
              name="Kustaa Huhtala"
              email="kustaa.huhtala@rebase.fi"
              linkedIn="https://www.linkedin.com/in/kustaahuhtala/"
            />
          </ApplyBoxTitles>
          <ApplyForm />
        </ContentWrapper>
      </FormBoxContainer>
    </>
  )
}

export default ApplyBox
