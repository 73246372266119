import * as React from "react"
import { useEffect } from "react"
import { createRef } from "react"
import styled from "styled-components"
import { useItems } from "../../hooks/useItems"
import SecondaryTitle from "../common/SecondaryTitle"
import OurPeopleNavigationBar from "./OurPeopleNavigationBar"
import Person from "./Person"

const MeetOurPeopleContainer = styled.div`
  margin-top: 5.5rem;

  @media (min-width: 64em) and (min-height: 40em) {
    margin-top: 7.5rem;
  }
  @media (min-width: 40em) {
    display: flex;
    flex-direction: column;
  }
`

const ShowCase = styled.div`
  align-items: center;
  width: min(950px, 100% - 2.5rem);
  height: fit-content;
  gap: 2rem;
  margin-top: 3rem;

  margin-left: auto;
  margin-right: auto;

  max-width: unset;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));

  @media (min-width: 40em) and (min-height: 40em) {
    gap: 2.5rem;
    grid-template-columns: repeat(auto-fit, minmax(205px, 1fr));
  }
`

const ShowCaseContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;

  @media (min-width: 64em) {
    align-items: flex-start;
    flex-direction: row;
  }
  /* ↑↓ safari cant handle these two being together */
  @media (orientation: landscape) and (max-height: 40em) {
    align-items: flex-start;
    flex-direction: row;
  }
`

const GridItem = styled.div`
  aspect-ratio: 1/1;
  max-width: 20rem;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  @media (min-width: 40em) {
  }
`

const SeeMoreButton = styled.button`
  font-family: var(--ff-secondary);
  font-weight: 700;
  font-size: 1em;
  line-height: 26px;
  letter-spacing: 0.0046em;
  color: var(--clr-text-blue);
  width: 200px;
  height: 46px;
  padding-top: 4px;
  padding-bottom: 2px;

  cursor: pointer;
  text-transform: uppercase;
  text-decoration: none;
  text-align: center;

  background-color: transparent;
  border: 2px solid var(--clr-primary);
`

const SeeMoreContent = styled.div`
  margin-top: 4rem;
  text-align: center;
  margin-bottom: 5.5rem;

  @media (min-width: 40em) {
    margin-top: 5rem;
  }
  @media (min-width: 64em) {
    margin-bottom: 10.31rem;
  }
`

const SnapTo = styled.span`
  scroll-margin-top: 74px;
  @media (min-width: 40em) {
    scroll-margin-top: 70px;
  }
`

const MeetOurPeople = ({ data }) => {
  const gridRef = createRef()
  const [visibleItems, hasMore, loadMore, updateVisibleItems] = useItems(
    data.allPeopleDataJson.nodes,
    gridRef
  )

  console.log(data.allPeopleDataJson.nodes)

  useEffect(() => {
    updateVisibleItems(data.allPeopleDataJson.nodes)
  }, [])

  const changeCategory = filter => {
    updateVisibleItems(
      data.allPeopleDataJson.nodes.filter(item => filter.test(item.title))
    )
  }

  return (
    <MeetOurPeopleContainer id="meet-our-people-container">
      <SecondaryTitle>Meet our people</SecondaryTitle>
      <SnapTo id="meet-our-people" />
      <OurPeopleNavigationBar changeCategory={changeCategory} />
      <ShowCaseContainer>
        <ShowCase ref={gridRef}>
          {visibleItems.map(person => {
            return (
              <GridItem key={person.id}>
                <Person person={person} />
              </GridItem>
            )
          })}{" "}
        </ShowCase>
      </ShowCaseContainer>
      <SeeMoreContent>
        {hasMore ? (
          <SeeMoreButton onClick={loadMore}>See more</SeeMoreButton>
        ) : (
          <></>
        )}
      </SeeMoreContent>
    </MeetOurPeopleContainer>
  )
}

export default MeetOurPeople
