import React, { useState } from "react"
import styled from "styled-components"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import PersonalLinks from "./PersonalLinks"
import { formatPhoneNumber } from "../../utils/formatPhoneNumber"

const PersonBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  min-height: 335px;
  justify-content: flex-start;

  position: relative;
  width: 100%;
  align-items: center;

  h4 {
    margin: 0;
    line-height: 1.25;
  }
  @media (min-width: 40em) {
    width: 100%;
    min-height: 355px;
  }

  @media (min-width: 46em) {
    min-height: 388px;
    max-width: 250px;
  }

  @media (min-width: 61.25em) {
    max-width: none;
    min-height: 355px;
  }

  @media (max-width: 64em) {
    -webkit-mask-image: -webkit-radial-gradient(white, black);
    overflow: clip;
  }
`

const StyledImage = styled(GatsbyImage)``

const InformationContainer = styled.div`
  background: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
  width: 100%;
  margin-top: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  h3 {
    margin: 0;
    line-height: 28px;
    font-size: 1.25em;
    font-weight: 700;
    color: #0b0576;
    font-family: var(--ff-seondary);
    font-weight: 800;

    @media (min-width: 40em) {
      min-height: none;
    }
  }

  p {
    margin: 0;
    color: var(--clr-text-blue);
    text-align: left;

    font-family: var(--ff-primary);
    font-weight: 500;
    font-size: 0.875em;
    line-height: 21px;
    letter-spacing: 0.0015em;
    color: #0b0576;

    @media (min-width: 40em) {
      min-height: none;
    }
  }
`

const Person = ({ person }) => {
  const [showEmail, setShowEmail] = useState(false)
  const [showPhone, setShowPhone] = useState(false)
  const { name, title, image, personalLinks } = person

  const toggleEmail = () => {
    setShowEmail(!showEmail)
  }

  const togglePhone = () => {
    setShowPhone(!showPhone)
  }

  return (
    <PersonBox>
      <StyledImage image={getImage(image)} alt="Employee photo" />
      <InformationContainer>
        <h3>{name}</h3>
        <p>{title}</p>
        {showPhone && <p>{formatPhoneNumber(personalLinks.phone)}</p>}
        {showEmail && <p>{personalLinks.email}</p>}
        <PersonalLinks
          personalLinks={personalLinks}
          toggleEmail={toggleEmail}
          togglePhone={togglePhone}
        />
      </InformationContainer>
    </PersonBox>
  )
}

export default Person
